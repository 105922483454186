import useLocalStorageState from './useLocalStorageState'

const useAvailablePlans = (initialValue) => {
  const [availablePlans, setAvailablePlans] = useLocalStorageState(
    'availability-data',
    initialValue
  )
  const [selectedPlan, setSelectedPlan] = useLocalStorageState(
    'availability-selected-package',
    null
  )
  return { availablePlans, setAvailablePlans, selectedPlan, setSelectedPlan }
}

export default useAvailablePlans
