import styled from '@emotion/styled'
import { css } from '@emotion/core'

// Loading animations for the LoadingStep component
export const Spinner = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing.xs} 0;
    max-height: 50px;

    @keyframes blob-left-anim {
      0% {
        background-color: ${theme.colors.secondary.base.hex};
        transform: scale(1) translate(0, 0);
      }
      33% {
        background-color: ${theme.colors.primary.base.hex};
        transform: scale(0.55, 0.5) translate(80px, 0);
      }
      66% {
        transform: scale(0.8) translate(0, 0);
      }
      100% {
        background-color: ${theme.colors.secondary.base.hex};
        transform: scale(1) translate(0, 0);
      }
    }

    @keyframes blob-right-anim {
      0% {
        background-color: ${theme.colors.secondary.base.hex};
        transform: scale(1) translate(0, 0);
      }
      33% {
        background-color: ${theme.colors.primary.base.hex};
        transform: scale(0.55, 0.5) translate(-80px, 0);
      }
      66% {
        transform: scale(0.8) translate(0, 0);
      }
      100% {
        background-color: ${theme.colors.secondary.base.hex};
        transform: scale(1) translate(0, 0);
      }
    }
  `
)

export const Blobs = styled.div`
  filter: url('#goo');
  display: block;
  height: 50px;
  width: 200px;
  margin: auto;
`

export const Blob = styled.div(
  ({ left, theme }) => css`
    background-color: ${theme.colors.light.darker.hex};
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    ${left === true
      ? `animation: blob-left-anim cubic-bezier(0.77, 0, 0.175, 1) 2s infinite;`
      : `animation: blob-right-anim cubic-bezier(0.77, 0, 0.175, 1) 2s infinite;
`}
  `
)
