import * as React from 'react'
import * as Styled from './Spinner.styles'

// all credit for the animation goes to apester https://codepen.io/team/apester/pen/pEEVRO
const Spinner = () => (
  <Styled.Spinner>
    <Styled.Blobs>
      <Styled.Blob left />
      <Styled.Blob />
    </Styled.Blobs>

    <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
            result="goo"
          />
          <feBlend in2="goo" in="SourceGraphic" result="mix" />
        </filter>
      </defs>
    </svg>
  </Styled.Spinner>
)

export default Spinner
